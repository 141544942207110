import type { RouteComponent, RouteRecordRaw } from 'vue-router';

type Lazy<T> = () => Promise<T>;

/**
 * Route definition interface that includes path, regex pattern for validation,
 * and any other metadata needed
 */
export type RouteDefinition = RouteRecordRaw & {
  /** Route name constant */
  name: string;

  /** Route path used in router */
  path: string;

  /** Regex pattern for validation (null means don't allow redirect to this route) */
  pattern: RegExp | null;

  /** Whether this is a public route that doesn't require authentication */
  public?: boolean;

  /** Any additional metadata needed for the route */
  meta?: Record<string, any>;

  /** Whether this route should be skipped in production */
  skip?: boolean;

  /** Component to be used for the route */
  component: RouteComponent | Lazy<RouteComponent>;
};

/**
 * Feature flags for routes that are under development or planned for future release
 *
 * How to use:
 * 1. Add a new feature flag to this object with a descriptive name
 * 2. Use environment variables to control the flag (VITE_ENABLE_*)
 * 3. Use the spread operator with a conditional array to include the route:
 *    ...(FEATURE_FLAGS.YOUR_FLAG ? [{ route config }] : [])
 *
 * To enable a feature in development:
 * - Add VITE_ENABLE_FEATURE=true to your .env.local file
 * - Or set the flag directly to true for local testing
 */
export const FEATURE_FLAGS: Record<string, boolean> = {
  PROJECTS_STATISTICS: import.meta.env.VITE_ENABLE_PROJECTS_STATISTICS === 'true',
  MILESTONE_DETAIL: import.meta.env.VITE_ENABLE_MILESTONE_DETAIL === 'true',
  REGISTRATION: import.meta.env.VITE_ENABLE_REGISTRATION === 'true',
};

/**
 * Route name constants - use these for navigation
 */
export const ROUTES_NAME = {
  HOME: 'Home',
  LOGIN: 'Login',
  REGISTRATION: 'Registration',
  ACTIVATION: 'Activation',
  MESSENGER_ACTIVE: 'Messenger',
  MESSENGER_ARCHIVE: 'MessengerArchive',
  MESSENGER_CHAT_BY_CHAIN: 'MessengerChatByChain',
  MESSENGER_CHAT_BY_USER: 'MessengerChatByUser',
  USERS: 'Users',
  USER_BY_ID: 'UserById',
  NOT_FOUND: 'NotFound',
  GROUPS: 'Groups',
  GROUP_BY_ID: 'GroupById',
  GROUP_DASHBOARD: 'GroupDashboard',
  NOTIFICATIONS: 'Notifications',
  FEED: 'Feed',
  POST_BY_ID: 'PostById',
  DOCS: 'Docs',
  FILE_BY_ID: 'FileById',
  PAGES: 'Pages',
  PAGE_BY_ID: 'PageById',
  PAGE_EDIT: 'PageEdit',
  CALENDAR: 'Calendar',
  SEARCH: 'Search',
  WIKIS: 'Wikis',
  WIKI_BY_ID: 'WikiById',
  WIKI_EDIT: 'WikiEdit',
  WIKI_CREATE: 'WikiCreate',
  WIKI_COMPARE: 'WikiCompare',
  TOPICS: 'Topics',
  TOPIC_BY_ID: 'TopicById',
  USAGE_RULES: 'UsageRules',
  PROJECT_BY_ID: 'ProjectById',
  PROJECTS: 'Projects',
  PROJECTS_STATISTICS: 'ProjectsStatistics',
  TASKS: 'Tasks',
  MILESTONES: 'Milestones',
  MILESTONE_BY_ID: 'MilestoneById',
  OFFICE: 'Office',
  AI_ASSISTANT: 'AiAssistant',
  IDEAS: 'Ideas',
  NETWORKS: 'Networks',
  LOADING: 'Loading',
  SETTINGS: 'Settings',
  ADMIN_DESIGN: 'AdminDesign',
  ADMIN_EMAIL_FOOTER: 'AdminEmailFooter',
  ADMIN_NETWORK_SETTINGS: 'AdminNetworkSettings',
  ADMIN_NETWORK_DOMAIN_LIST: 'AdminNetworkDomainList',
  ADMIN_BRANDING: 'AdminBranding',
  ADMIN_MOBILE_APPS: 'AdminMobileApps',
  ADMIN_USAGE_RULES: 'AdminUsageRules',
  ADMIN_PASSWORD_SETTINGS: 'AdminPasswordSettings',
  ADMIN_APPLICATIONS: 'AdminApplications',
  ADMIN_BANNER: 'AdminBanner',
  ADMIN_TAGS: 'AdminTags',
  ADMIN_USER_MANAGEMENT: 'AdminUserManagement',
  ADMIN_INVITE_USER: 'AdminInviteUser',
  ADMIN_RESTORE_POST: 'AdminRestorePost',
  ADMIN_BADGES: 'AdminBadges',
  ADMIN_STATISTICS: 'AdminStatistics',
  UI_KIT_ICONS: 'UiKitIcons',
  AUTH: 'Auth',
} as const;

export type RouteName = (typeof ROUTES_NAME)[keyof typeof ROUTES_NAME];

/**
 * Route definitions - single source of truth
 *
 * This map includes all information about routes:
 * - name: The route name constant
 * - path: The actual URL path
 * - pattern: Regex pattern for validation
 * - public: Whether the route is public (doesn't require auth)
 * - meta: Any additional metadata
 */
export const ROUTE_DEFINITIONS: Record<RouteName, RouteDefinition> = {
  // Root routes
  [ROUTES_NAME.HOME]: {
    name: ROUTES_NAME.HOME,
    path: '/',
    pattern: /^\/?$/,
    component: () => import('@/views/HomePage.vue'),
  },

  // Auth routes
  [ROUTES_NAME.LOGIN]: {
    name: ROUTES_NAME.LOGIN,
    path: '/login',
    pattern: null, // Prevent redirect loops
    public: true,
    meta: { layout: 'auth' },
    component: () => import('@/views/Auth/LoginPage.vue'),
  },
  [ROUTES_NAME.REGISTRATION]: {
    name: ROUTES_NAME.REGISTRATION,
    path: '/registration',
    pattern: /^\/registration\/?$/,
    public: true,
    meta: { layout: 'auth' },
    skip: !FEATURE_FLAGS.REGISTRATION,
    component: () => import('@/views/Auth/RegistrationPage.vue'),
  },
  [ROUTES_NAME.ACTIVATION]: {
    name: ROUTES_NAME.ACTIVATION,
    path: '/activation/:id',
    pattern: /^\/activation\/\d+\/?$/,
    public: true,
    meta: { layout: 'auth' },
    skip: !FEATURE_FLAGS.REGISTRATION,
    component: () => import('@/views/Auth/RegistrationPage.vue'),
  },
  [ROUTES_NAME.AUTH]: {
    name: ROUTES_NAME.AUTH,
    path: '/auth',
    pattern: /^\/auth\/?$/,
    public: true,
    component: () => import('@/views/Common/LoadingPage.vue'),
  },

  // Common routes
  [ROUTES_NAME.FEED]: {
    name: ROUTES_NAME.FEED,
    path: '/feed',
    pattern: /^\/feed\/?$/,
    component: () => import('@/views/Feed/FeedPage.vue'),
  },
  [ROUTES_NAME.DOCS]: {
    name: ROUTES_NAME.DOCS,
    path: '/docs/:pathMatch(.*)*',
    pattern: /^\/docs\/.*$/,
    component: () => import('@/views/Docs/DocsPage.vue'),
  },
  [ROUTES_NAME.USERS]: {
    name: ROUTES_NAME.USERS,
    path: '/users',
    pattern: /^\/users\/?$/,
    component: () => import('@/views/Users/UsersPage.vue'),
    props: (route) => ({ searchText: route.query.searchText }),
  },
  [ROUTES_NAME.USER_BY_ID]: {
    name: ROUTES_NAME.USER_BY_ID,
    path: '/user/:id',
    pattern: /^\/user\/\d+\/?$/,
    component: () => import('@/views/Users/UserPage.vue'),
  },
  [ROUTES_NAME.GROUPS]: {
    name: ROUTES_NAME.GROUPS,
    path: '/groups',
    pattern: /^\/groups\/?$/,
    component: () => import('@/views/Groups/GroupsPage.vue'),
    props: (route) => ({ searchText: route.query.searchText }),
  },
  [ROUTES_NAME.GROUP_BY_ID]: {
    name: ROUTES_NAME.GROUP_BY_ID,
    path: '/group/:id',
    pattern: /^\/group\/\d+\/?$/,
    component: () => import('@/views/Groups/GroupPage.vue'),
  },
  [ROUTES_NAME.GROUP_DASHBOARD]: {
    name: ROUTES_NAME.GROUP_DASHBOARD,
    path: '/group/:id/dashboard',
    pattern: /^\/group\/\d+\/dashboard\/?$/,
    component: () => import('@/views/Groups/GroupDashboardPage.vue'),
  },
  [ROUTES_NAME.NOTIFICATIONS]: {
    name: ROUTES_NAME.NOTIFICATIONS,
    path: '/notifications',
    pattern: /^\/notifications\/?$/,
    component: () => import('@/views/Notifications/NotificationsPage.vue'),
  },
  [ROUTES_NAME.POST_BY_ID]: {
    name: ROUTES_NAME.POST_BY_ID,
    path: '/post/:id',
    pattern: /^\/post\/\d+\/?$/,
    component: () => import('@/views/FeedItem/FeedItemPage.vue'),
  },
  [ROUTES_NAME.FILE_BY_ID]: {
    name: ROUTES_NAME.FILE_BY_ID,
    path: '/file/:id',
    pattern: /^\/file\/\d+\/?$/,
    component: () => import('@/views/File/FilePage.vue'),
  },
  [ROUTES_NAME.PAGES]: {
    name: ROUTES_NAME.PAGES,
    path: '/pages',
    pattern: /^\/pages\/?$/,
    component: () => import('@/views/CustomPages/CustomPages.vue'),
  },
  [ROUTES_NAME.PAGE_BY_ID]: {
    name: ROUTES_NAME.PAGE_BY_ID,
    path: '/pages/:id',
    pattern: /^\/pages\/\d+\/?$/,
    component: () => import('@/views/CustomPages/CustomPage.vue'),
  },
  [ROUTES_NAME.PAGE_EDIT]: {
    name: ROUTES_NAME.PAGE_EDIT,
    path: '/pages/:id/edit',
    pattern: /^\/pages\/\d+\/edit\/?$/,
    component: () => import('@/views/CustomPages/CustomPageEdit.vue'),
  },
  [ROUTES_NAME.CALENDAR]: {
    name: ROUTES_NAME.CALENDAR,
    path: '/calendar',
    pattern: /^\/calendar\/?$/,
    component: () => import('@/views/Calendar/CalendarPage.vue'),
  },
  [ROUTES_NAME.SEARCH]: {
    name: ROUTES_NAME.SEARCH,
    path: '/search',
    pattern: /^\/search\/?$/,
    component: () => import('@/views/Search/SearchPage.vue'),
  },

  // Wiki routes
  [ROUTES_NAME.WIKIS]: {
    name: ROUTES_NAME.WIKIS,
    path: '/wikis',
    pattern: /^\/wikis\/?$/,
    component: () => import('@/views/Wikis/WikisPage.vue'),
  },
  [ROUTES_NAME.WIKI_BY_ID]: {
    name: ROUTES_NAME.WIKI_BY_ID,
    path: '/wiki/:id/:versionId?',
    pattern: /^\/wiki\/\d+\/?(\d+)?\/?$/,
    component: () => import('@/views/Wikis/WikiPage.vue'),
  },
  [ROUTES_NAME.WIKI_EDIT]: {
    name: ROUTES_NAME.WIKI_EDIT,
    path: '/wiki/edit/:id',
    pattern: /^\/wiki\/edit\/\d+\/?$/,
    component: () => import('@/views/Wikis/WikiEditPage.vue'),
  },
  [ROUTES_NAME.WIKI_CREATE]: {
    name: ROUTES_NAME.WIKI_CREATE,
    path: '/wiki/create',
    pattern: /^\/wiki\/create\/?$/,
    component: () => import('@/views/Wikis/WikiCreatePage.vue'),
  },
  [ROUTES_NAME.WIKI_COMPARE]: {
    name: ROUTES_NAME.WIKI_COMPARE,
    path: '/wiki/compare/:id',
    pattern: /^\/wiki\/compare\/\d+\/?$/,
    component: () => import('@/views/Wikis/WikiComparePage.vue'),
    props: (route) => ({ ...route.params, ...route.query }),
  },

  // Topics routes
  [ROUTES_NAME.TOPICS]: {
    name: ROUTES_NAME.TOPICS,
    path: '/topics',
    pattern: /^\/topics\/?$/,
    component: () => import('@/views/Topics/TopicsPage.vue'),
  },
  [ROUTES_NAME.TOPIC_BY_ID]: {
    name: ROUTES_NAME.TOPIC_BY_ID,
    path: '/topic/:id',
    pattern: /^\/topic\/\d+\/?$/,
    component: () => import('@/views/Topics/TopicPage.vue'),
  },

  // Projects routes
  [ROUTES_NAME.PROJECTS]: {
    name: ROUTES_NAME.PROJECTS,
    path: '/projects',
    pattern: /^\/projects\/?$/,
    component: () => import('@/views/Projects/ProjectsPage.vue'),
  },
  [ROUTES_NAME.PROJECTS_STATISTICS]: {
    name: ROUTES_NAME.PROJECTS_STATISTICS,
    path: '/projects/statistics',
    pattern: /^\/projects\/statistics\/?$/,
    component: () => import('@/views/Projects/StatisticsPage.vue'),
    meta: { requiresProjectsPermission: true },
    skip: !FEATURE_FLAGS.PROJECTS_STATISTICS,
  },
  [ROUTES_NAME.PROJECT_BY_ID]: {
    name: ROUTES_NAME.PROJECT_BY_ID,
    path: '/projects/:projectId',
    pattern: /^\/projects\/\d+\/?$/,
    component: () => import('@/views/Projects/ProjectPage.vue'),
  },

  // Task management
  [ROUTES_NAME.TASKS]: {
    name: ROUTES_NAME.TASKS,
    path: '/tasks',
    pattern: /^\/tasks\/?$/,
    component: () => import('@/views/Projects/TasksPage.vue'),
  },
  [ROUTES_NAME.MILESTONES]: {
    name: ROUTES_NAME.MILESTONES,
    path: '/project/:projectId/milestones',
    pattern: /^\/project\/\d+\/milestones\/?$/,
    component: () => import('@/views/Projects/MilestonesPage.vue'),
    meta: { requiresProjectsPermission: true },
  },
  [ROUTES_NAME.MILESTONE_BY_ID]: {
    name: ROUTES_NAME.MILESTONE_BY_ID,
    path: '/project/:projectId/milestone/:milestoneId',
    pattern: /^\/project\/\d+\/milestone\/\d+\/?$/,
    component: () => import('@/views/Projects/MilestonePage.vue'),
    meta: { requiresProjectsPermission: true },
    skip: !FEATURE_FLAGS.MILESTONE_DETAIL,
  },

  // Messenger routes
  [ROUTES_NAME.MESSENGER_ACTIVE]: {
    name: ROUTES_NAME.MESSENGER_ACTIVE,
    path: '/messenger',
    pattern: /^\/messenger\/?$/,
    component: () => import('@/views/Messenger/MessengerPage.vue'),
    meta: { isMessenger: true, isArchive: false },
  },
  [ROUTES_NAME.MESSENGER_ARCHIVE]: {
    name: ROUTES_NAME.MESSENGER_ARCHIVE,
    path: '/messenger/archive',
    pattern: /^\/messenger\/archive\/?$/,
    component: () => import('@/views/Messenger/MessengerPage.vue'),
    meta: { isMessenger: true, isArchive: true },
  },
  [ROUTES_NAME.MESSENGER_CHAT_BY_CHAIN]: {
    name: ROUTES_NAME.MESSENGER_CHAT_BY_CHAIN,
    path: '/messenger/chain/:id',
    pattern: /^\/messenger\/chain\/\d+\/?$/,
    component: () => import('@/views/Messenger/ChatPage.vue'),
    meta: { type: 'chain', isMessenger: true },
  },
  [ROUTES_NAME.MESSENGER_CHAT_BY_USER]: {
    name: ROUTES_NAME.MESSENGER_CHAT_BY_USER,
    path: '/messenger/user/:id',
    pattern: /^\/messenger\/user\/\d+\/?$/,
    component: () => import('@/views/Messenger/ChatPage.vue'),
    meta: { type: 'user', isMessenger: true },
  },

  // Other routes
  [ROUTES_NAME.USAGE_RULES]: {
    name: ROUTES_NAME.USAGE_RULES,
    path: '/usage-rules',
    pattern: /^\/usage-rules\/?$/,
    component: () => import('@/views/Networks/UsageRules/UsageRulesView.vue'),
  },
  [ROUTES_NAME.OFFICE]: {
    name: ROUTES_NAME.OFFICE,
    path: '/office/:payload',
    pattern: /^\/office\/.*\/?$/,
    component: () => import('@/views/Office/OfficeView.vue'),
    props: (route) => ({ ...route.params, ...route.query }),
  },
  [ROUTES_NAME.AI_ASSISTANT]: {
    name: ROUTES_NAME.AI_ASSISTANT,
    path: '/ai-assistant',
    pattern: /^\/ai-assistant\/?$/,
    component: () => import('@/views/AiAssistant/AiAssistantPage.vue'),
  },
  [ROUTES_NAME.IDEAS]: {
    name: ROUTES_NAME.IDEAS,
    path: '/ideas',
    pattern: /^\/ideas\/?$/,
    component: () => import('@/views/Ideas/IdeasPage.vue'),
  },
  [ROUTES_NAME.NETWORKS]: {
    name: ROUTES_NAME.NETWORKS,
    path: '/networks',
    pattern: /^\/networks\/?$/,
    skip: !__DEV__,
    component: () => import('@/views/Networks/NetworksPage.vue'),
  },
  [ROUTES_NAME.LOADING]: {
    name: ROUTES_NAME.LOADING,
    path: '/loading',
    pattern: /^\/loading\/?$/,
    component: () => import('@/views/Common/LoadingPage.vue'),
  },
  [ROUTES_NAME.SETTINGS]: {
    name: ROUTES_NAME.SETTINGS,
    path: '/account/settings',
    pattern: /^\/account\/settings\/?$/,
    component: () => import('@/views/Settings/SettingsPage.vue'),
  },

  // Admin routes
  [ROUTES_NAME.ADMIN_DESIGN]: {
    name: ROUTES_NAME.ADMIN_DESIGN,
    path: '/admin/design',
    pattern: /^\/admin\/design\/?$/,
    component: () => import('@/views/Admin/Design/AdminDesignPage.vue'),
  },
  [ROUTES_NAME.ADMIN_EMAIL_FOOTER]: {
    name: ROUTES_NAME.ADMIN_EMAIL_FOOTER,
    path: '/admin/email-footer',
    pattern: /^\/admin\/email-footer\/?$/,
    component: () => import('@/views/Admin/AdminEmailFooterPage.vue'),
  },
  [ROUTES_NAME.ADMIN_NETWORK_SETTINGS]: {
    name: ROUTES_NAME.ADMIN_NETWORK_SETTINGS,
    path: '/admin/network-settings',
    pattern: /^\/admin\/network-settings\/?$/,
    component: () => import('@/views/Admin/NetworkSettings/AdminNetworkSettingsPage.vue'),
    meta: { requiresAdminRights: true },
  },
  [ROUTES_NAME.ADMIN_NETWORK_DOMAIN_LIST]: {
    name: ROUTES_NAME.ADMIN_NETWORK_DOMAIN_LIST,
    path: '/admin/domain-list',
    pattern: /^\/admin\/domain-list\/?$/,
    component: () => import('@/views/Admin/NetworkSettings/AdminNetworkDomainListPage.vue'),
    meta: { requiresAdminRights: true },
  },
  [ROUTES_NAME.ADMIN_BRANDING]: {
    name: ROUTES_NAME.ADMIN_BRANDING,
    path: '/admin/branding',
    pattern: /^\/admin\/branding\/?$/,
    component: () => import('@/views/Admin/NetworkSettings/AdminBrandingPage.vue'),
    meta: { requiresAdminRights: true },
  },
  [ROUTES_NAME.ADMIN_MOBILE_APPS]: {
    name: ROUTES_NAME.ADMIN_MOBILE_APPS,
    path: '/admin/mobile-apps',
    pattern: /^\/admin\/mobile-apps\/?$/,
    component: () => import('@/views/Admin/NetworkSettings/AdminMobileAppsPage.vue'),
    meta: { requiresAdminRights: true },
  },
  [ROUTES_NAME.ADMIN_USAGE_RULES]: {
    name: ROUTES_NAME.ADMIN_USAGE_RULES,
    path: '/admin/usage-rules',
    pattern: /^\/admin\/usage-rules\/?$/,
    component: () => import('@/views/Admin/AdminUsageRulesPage.vue'),
  },
  [ROUTES_NAME.ADMIN_PASSWORD_SETTINGS]: {
    name: ROUTES_NAME.ADMIN_PASSWORD_SETTINGS,
    path: '/admin/password-settings',
    pattern: /^\/admin\/password-settings\/?$/,
    component: () => import('@/views/Admin/AdminPasswordSettingsPage.vue'),
  },
  [ROUTES_NAME.ADMIN_APPLICATIONS]: {
    name: ROUTES_NAME.ADMIN_APPLICATIONS,
    path: '/admin/applications',
    pattern: /^\/admin\/applications\/?$/,
    component: () => import('@/views/Admin/AdminApplicationsPage.vue'),
  },
  [ROUTES_NAME.ADMIN_STATISTICS]: {
    name: ROUTES_NAME.ADMIN_STATISTICS,
    path: '/admin/statistics',
    pattern: /^\/admin\/statistics\/?$/,
    component: () => import('@/views/Admin/AdminStatisticsPage.vue'),
  },
  [ROUTES_NAME.ADMIN_BANNER]: {
    name: ROUTES_NAME.ADMIN_BANNER,
    path: '/admin/banner',
    pattern: /^\/admin\/banner\/?$/,
    component: () => import('@/views/Admin/AdminBannerPage.vue'),
  },
  [ROUTES_NAME.ADMIN_TAGS]: {
    name: ROUTES_NAME.ADMIN_TAGS,
    path: '/admin/tags',
    pattern: /^\/admin\/tags\/?$/,
    component: () => import('@/views/Admin/AdminTagsPage.vue'),
  },
  [ROUTES_NAME.ADMIN_USER_MANAGEMENT]: {
    name: ROUTES_NAME.ADMIN_USER_MANAGEMENT,
    path: '/admin/user-management',
    pattern: /^\/admin\/user-management\/?$/,
    component: () => import('@/views/Admin/AdminUserManagementPage.vue'),
  },
  [ROUTES_NAME.ADMIN_INVITE_USER]: {
    name: ROUTES_NAME.ADMIN_INVITE_USER,
    path: '/admin/invite-user',
    pattern: /^\/admin\/invite-user\/?$/,
    component: () => import('@/views/Admin/AdminInviteUserPage.vue'),
  },
  [ROUTES_NAME.ADMIN_RESTORE_POST]: {
    name: ROUTES_NAME.ADMIN_RESTORE_POST,
    path: '/admin/restore-post',
    pattern: /^\/admin\/restore-post\/?$/,
    component: () => import('@/views/Admin/AdminRestorePostPage.vue'),
  },
  [ROUTES_NAME.ADMIN_BADGES]: {
    name: ROUTES_NAME.ADMIN_BADGES,
    path: '/admin/badges',
    pattern: /^\/admin\/badges\/?$/,
    component: () => import('@/views/Admin/AdminBadgesPage.vue'),
  },

  // Misc routes
  [ROUTES_NAME.UI_KIT_ICONS]: {
    name: ROUTES_NAME.UI_KIT_ICONS,
    path: '/ui-kit/icons',
    pattern: /^\/ui-kit\/icons\/?$/,
    skip: !__DEV__,
    component: () => import('@/views/UiKit/UiKitIconsPage.vue'),
  },
  [ROUTES_NAME.NOT_FOUND]: {
    name: ROUTES_NAME.NOT_FOUND,
    path: '/not-found',
    pattern: /^\/not-found\/?$/,
    public: true,
    component: () => import('@/views/Common/NotFound.vue'),
  },
};

/**
 * Routes that don't require authentication
 */
export const NO_AUTH_ROUTES: RouteName[] = [ROUTES_NAME.LOGIN, ROUTES_NAME.REGISTRATION, ROUTES_NAME.ACTIVATION];

/**
 * Helper to create route configs from route definitions
 */
export function createRouteConfig(): RouteRecordRaw[] {
  const routes: RouteRecordRaw[] = [];

  for (const [routeName, routeDef] of Object.entries(ROUTE_DEFINITIONS)) {
    if (routeDef.skip) continue;

    routes.push({
      path: routeDef.path,
      name: routeName,
      meta: routeDef.meta,
      component: routeDef.component,
      props: routeDef.props,
    });
  }

  routes.push({
    path: '/:pathMatch(.*)*',
    name: ROUTES_NAME.NOT_FOUND,
    component: () => import('@/views/Common/NotFound.vue'),
  });

  return routes;
}

/**
 * Validates if a URL path corresponds to a valid route in the application
 *
 * @param value The URL path to validate
 * @returns Whether the path is valid and allowed for redirection
 */
export function isValidRoute(value: string): boolean {
  if (!value || value === '/') return false;

  try {
    // Handle both absolute and relative URLs
    let path: string;
    if (value.startsWith('http')) {
      // For absolute URLs, parse the URL and extract the pathname
      const url = new URL(value);
      path = url.pathname;
    } else {
      // For relative URLs, use the path directly
      path = value.startsWith('/') ? value : `/${value}`;
    }

    // Remove query params and hash if present
    path = path.split('?')[0].split('#')[0];

    // Check if the path matches any of the valid route patterns
    for (const [, routeDef] of Object.entries(ROUTE_DEFINITIONS)) {
      // Skip null patterns (routes we don't want to redirect to)
      if (!routeDef.pattern) continue;

      if (routeDef.pattern.test(path)) return true;
    }

    return false;
  } catch (error) {
    console.error('[ERROR] Error in route validation:', error);
    return false;
  }
}
